import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import QRCode from "qrcode"

export const wordsSlice = createSlice({
	name: "words",
	initialState: {
                deviceBatch: undefined,
		challenge: undefined,
		derSignature: undefined,
		qrUrl: undefined,
		words: ["", "", "", ""],
		submitted: false,
		requestInProgress: false,
		result: undefined,
		scrollToTop: false,
	},
	reducers: {
                setDeviceBatch: (state, action) => {
                        state.deviceBatch = action.payload
                },
		setChallenge: (state, action) => {
			state.challenge = action.payload
		},
		setDerSignature: (state, action) => {
			state.derSignature = action.payload
		},
		setQrUrl: (state, action) => {
			state.qrUrl = action.payload
		},
		setWord: (state, action) => {
			const { index, word } = action.payload
			state.words[index] = word.trim()
		},
		clear: (state) => {
			state.words = ["", "", "", ""]
		},
		submit: (state) => {
			state.submitted = true
			state.requestInProgress = true
		},
		setResult: (state, action) => {
			state.requestInProgress = false
			state.result = action.payload
		},
		setScrollToTop: (state, action) => {
			const value = action.payload
			state.scrollToTop = value
		}
	},
})

export const { setWord, clear, submit, setResult, setChallenge, setDerSignature, setQrUrl, setDeviceBatch, setScrollToTop } = wordsSlice.actions

export const createQR = () => (dispatch, getState) => {
	const state = getState()
	QRCode.toDataURL(state.words.challenge, function (err, url) {
		dispatch(setQrUrl(url))
	})
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const submitAsync = () => (dispatch, getState) => {
	const state = getState()
	// We include the derSignature so that the server can verify that the request
	// came from it, otherwise we could be wasting time validating requests from
	// third party hackers.
	const { challenge, words, derSignature, deviceBatch } = state.words

	// The challenge is actually all the QR data...we just want the random number
	const parts = challenge.split(" ")
	const randomChallenge = parts[0]

	// Remember the state change
	dispatch(submit())

	axios
                .post("/validate",
                        { challenge: randomChallenge, response: words, derSignature },
                        { params: { batch: deviceBatch } }
                )
		.then((res) => {
			dispatch(setResult(res.data.isValidated))
			dispatch(setScrollToTop(true))
			// console.log("validate result=" + JSON.stringify(res))
		})
		.catch((error) => {
			// console.log("error=" + JSON.stringify(error))
			dispatch(setResult(error.message))
			// console.log("Error: " + error)
		})
}

export const getChallengeAsync = () => (dispatch) => {
	axios
		.get("/challenge")
		.then((res) => {
			// console.log("challenge result=" + JSON.stringify(res))
			dispatch(setChallenge(res.data.challenge + " " + res.data.signature))
			dispatch(setDerSignature(res.data.derSignature))
			dispatch(createQR())
			dispatch(setScrollToTop(true))
		})
		.catch((error) => {
			dispatch(setResult(error.message))
			// console.log("Error: " + error)
		})
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.words.words)`
export const selectDeviceBatch = (state) => state.words.deviceBatch
export const selectChallenge = (state) => state.words.challenge
export const selectDerSignature = (state) => state.words.derSignature
export const selectWords = (state) => state.words.words
export const selectSubmitted = (state) => state.words.submitted
export const selectRequestInProgress = (state) => state.words.requestInProgress
export const selectResult = (state) => state.words.result
export const selectQrUrl = (state) => state.words.qrUrl
export const selectScrollToTop= (state) => state.words.scrollToTop

export default wordsSlice.reducer
