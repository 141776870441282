import { batch, useSelector, useDispatch } from "react-redux"
import { Oval }  from "react-loader-spinner"
import {
        clear,
        getChallengeAsync,
        setDeviceBatch,
	setWord,
        setResult,
	submitAsync,
	selectWords,
	selectSubmitted,
	selectRequestInProgress,
	selectChallenge,
	selectResult,
	selectQrUrl,
        selectDeviceBatch,
} from "./wordsSlice"
import styles from "./Words.module.css"
import cx from "classnames"
import deviceFounders from "../../images/passport_foundersedition_web_resize.png"
import deviceBatch2 from "../../images/passport_batch2_web_resize.png"

export function Words() {
        const deviceBatch = useSelector(selectDeviceBatch)
	const challenge = useSelector(selectChallenge)
	const words = useSelector(selectWords)
	const submitted = useSelector(selectSubmitted)
	const result = useSelector(selectResult)
	const requestInProgress = useSelector(selectRequestInProgress)
	const qrUrl = useSelector(selectQrUrl)
	const dispatch = useDispatch()

        if (deviceBatch === undefined) {
                // ask user to select their device "batch" number
                return (
                        <div>
                                <p className={styles.message}>Select your Passport device.</p>
                                <div className={styles.deviceBox}>
                                        <div className={styles.deviceImage}>
                                                <img
                                                        src={deviceFounders}
                                                        alt="device founder's edition"
                                                        onClick={() => batch(() => {
                                                        dispatch(setDeviceBatch(1))
                                                        dispatch(getChallengeAsync())
                                                })} />
                                        </div>
                                        <button
                                                className={[styles.button, styles.buttonDevice].join(' ')}
                                                aria-label="Device Founder's Edition"
                                                onClick={() => batch(() => {
                                                dispatch(setDeviceBatch(1))
                                                dispatch(getChallengeAsync())
                                                })}
                                        > Founder's Edition
                                        </button>
                                </div>
                                <div className={styles.deviceBox}>
                                        <div className={styles.deviceImage}>
                                                <img
                                                        src={deviceBatch2}
                                                        alt="device passport"
                                                        onClick={() => batch(() => {
                                                        dispatch(setDeviceBatch(2))
                                                        dispatch(getChallengeAsync())
                                                })} />
                                        </div>
                                        <button
                                                className={[styles.button, styles.buttonDevice].join(' ')}
                                                aria-label="Device Passport"
                                                onClick={() => batch(() => {
                                                dispatch(setDeviceBatch(2))
                                                dispatch(getChallengeAsync())
                                                })}
                                        > Passport
                                        </button>
                                </div>
                        </div>
                )
        } else if (submitted && result !== undefined) {
                var buttonLabel;
                if (deviceBatch === 1) {
                        buttonLabel = "Valid"
                } else {
                        buttonLabel = "Passed"
                }

		return (
			<div>
			        <p className={cx({ [styles.messageLarge]: true })}>
                                        Security Check Result
                                </p>
				{result === true ? (
                                        <div>
                                                <p className={cx({ [styles.message]: true, [styles.success]: true })}>
                                                        Passed
                                                </p>
                                                <p className={cx({ [styles.message]: true })}>
                                                        Your Passport passed the Security Check. You can now select
                                                        {' '}
                                                        <strong>{buttonLabel}</strong> on Passport and continue the setup process.
                                                </p>
                                        </div>
				) : (
                                        <div>
                                                <p className={cx({ [styles.message]: true, [styles.error]: true })}>
                                                        Failed
                                                </p>
                                                <p className={cx({ [styles.message]: true })}>
                                                        The words you entered failed the Security Check. Are you sure you entered them correctly?
                                                </p>
                                                <p className={cx({ [styles.message]: true })}>
                                                        If you retry, the QR code will be updated with a new challenge,
                                                        and you will need to scan it again on Passport and enter the new words
                                                        that Passport displays.
                                                </p>
                                                <button
                                                        className={[styles.button, styles.buttonDevice].join(' ')}
                                                        aria-label="Retry Security Check"
                                                        onClick={() => batch(() => {
                                                        dispatch(clear())
                                                        dispatch(setResult(undefined))
                                                        dispatch(setDeviceBatch(deviceBatch))
                                                        dispatch(getChallengeAsync())
                                                        })}
                                                > Retry
                                                </button>
                                        </div>
				)}
			</div>
		)
	} else {
		return (
			<div>
				<p className={styles.message}>1. Scan the QR code below with your Passport.</p>
				{challenge === undefined ? (
					<div className={styles.loader}>
						<Oval color="#26becd" height={80} width={80} />
					</div>
				) : (
					<img className="QR" src={qrUrl} alt="QR Code" />
				)}
				<p className={styles.message}>
					2. Enter the four (4) validation words shown on Passport into the fields below, in the same order,
					and tap VALIDATE.
				</p>
				{words.map((word, index) => {
					return (
						<div className={styles.wordEntry} key={index}>
							<div className={styles.label}>Word {index + 1}:</div>
							<input
								className={styles.wordInput}
								value={word}
								onChange={(e) => dispatch(setWord({ index, word: e.target.value }))}
							/>
						</div>
					)
				})}
				<button
					className={styles.button}
					aria-label="Validate"
					onClick={() => dispatch(submitAsync())}
					disabled={requestInProgress}
				>
					VALIDATE
				</button>
				{/* <div className={styles.stateValue}>submitted: {submitted ? "true" : "false"}</div>
				<div className={styles.stateValue}>requestInProgress: {requestInProgress ? "true" : "false"}</div>
				<div className={styles.stateValue}>challenge: {challenge}</div>
				<div className={styles.stateValue}>result: {result}</div> */}
			</div>
		)
	}
}
