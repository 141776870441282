import React, {useRef, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import logo from "./images/logo.png"
import { Words } from "./features/words/Words"
import {
	selectScrollToTop, setScrollToTop,
} from "./features/words/wordsSlice"
import "./App.css"


function App() {
	const scrollRef = useRef()
	const scrollToTop = useSelector(selectScrollToTop)
	const dispatch = useDispatch()
	useEffect(() => {
		if (scrollToTop) {
			scrollRef.current.scrollTo(0,0)
			dispatch(setScrollToTop(false))
		}
	})

	return (
		<div ref={scrollRef} className="App">
			<div className="Card">
				<img src={logo} className="App-logo" alt="logo" />
				<p>PASSPORT SECURITY CHECK</p>
				<Words />
			</div>
		</div>
	)
}

export default App
